// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.star-ratings {
    unicode-bidi: bidi-override;
    color: #ccc;
    font-size: 20px;
    position: relative;
    margin: 0;
    padding: 0;
}

.star-ratings .fill-ratings {
    color: #e7711b;
    padding: 0;
    position: absolute;
    z-index: 1;
    display: block;
    top: 0;
    left: 0;
    overflow: hidden;
}

.star-ratings .fill-ratings span {
    display: inline-block;
}

.star-ratings .empty-ratings {
    padding: 0;
    display: block;
    z-index: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/Order/Order.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,cAAc;IACd,UAAU;IACV,kBAAkB;IAClB,UAAU;IACV,cAAc;IACd,MAAM;IACN,OAAO;IACP,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,UAAU;IACV,cAAc;IACd,UAAU;AACd","sourcesContent":[".star-ratings {\n    unicode-bidi: bidi-override;\n    color: #ccc;\n    font-size: 20px;\n    position: relative;\n    margin: 0;\n    padding: 0;\n}\n\n.star-ratings .fill-ratings {\n    color: #e7711b;\n    padding: 0;\n    position: absolute;\n    z-index: 1;\n    display: block;\n    top: 0;\n    left: 0;\n    overflow: hidden;\n}\n\n.star-ratings .fill-ratings span {\n    display: inline-block;\n}\n\n.star-ratings .empty-ratings {\n    padding: 0;\n    display: block;\n    z-index: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
