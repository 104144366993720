var appSettings;
if (window.location.hostname.includes('localhost')) {
    appSettings = {
        chat_module_url: 'http://localhost.c2c.com:6007',
        base_url: 'http://localhost.c2c.com/api',
        admin_backend_url: 'http://localhost.c2c.com',
        paypalSandBoxUrl: 'https://api-m.sandbox.paypal.com',
        paypalLiveUrl: 'https://api-m.paypal.com',
        react_app_endpoint: 'http://localhost:3000',
        paypalClientId: 'AWrtumbmv_RmehdaWXBN54RDppOvkMP_98VVmhpdqwQ0b9TjGnvhqR1bRR14CZK0TrTlp9lvZMC13cRN',
        paypalSecret: 'ENQij_QDUmt4ht9aIwfYiB1e-CjM8cwKliCTOSCXfS-ZftqXB3SHd-sGa3t4peo1cfKOskk8Lr5HgKaG',
        recordsPerPage: 12,
        recordsPerPageTable: 12,
        stripeKey: 'pk_test_51NUAgRG5smbJHPMoio9Xwvor0AnagTXRXUVpJ676ru9SqEPzwsy29cmnIY7sswcI39BJqfyWB6RAUdqTokFIL3XR00ERg9hNh5',
        stripeSecret: 'sk_test_51NUAgRG5smbJHPMoKYPJtCkkVtGkx54yISlVCZ1GYSRzFMVULyet4Q1bxFA8GyNoc7VGIqfATcRgekuFXEl2BG8K00N4tkyBWW'
    }
} else if(window.location.hostname.includes('netqom')){
    appSettings = {
        chat_module_url: 'http://c2c.netqom.com:6007',
        base_url: 'http://c2c.netqom.com/api',
        admin_backend_url: 'http://c2c.netqom.com',
        paypalSandBoxUrl: 'https://api-m.sandbox.paypal.com',
        paypalLiveUrl: 'https://api-m.paypal.com',
        react_app_endpoint: 'http://localhost:3000',
        paypalClientId: 'AWrtumbmv_RmehdaWXBN54RDppOvkMP_98VVmhpdqwQ0b9TjGnvhqR1bRR14CZK0TrTlp9lvZMC13cRN',
        paypalSecret: 'ENQij_QDUmt4ht9aIwfYiB1e-CjM8cwKliCTOSCXfS-ZftqXB3SHd-sGa3t4peo1cfKOskk8Lr5HgKaG',
        recordsPerPage: 12,
        recordsPerPageTable: 12,
        stripeKey: 'pk_test_51NUAgRG5smbJHPMoio9Xwvor0AnagTXRXUVpJ676ru9SqEPzwsy29cmnIY7sswcI39BJqfyWB6RAUdqTokFIL3XR00ERg9hNh5',
        stripeSecret: 'sk_test_51NUAgRG5smbJHPMoKYPJtCkkVtGkx54yISlVCZ1GYSRzFMVULyet4Q1bxFA8GyNoc7VGIqfATcRgekuFXEl2BG8K00N4tkyBWW'
    }
} else if(window.location.hostname.includes('myfileshosting')){
    appSettings = {
        chat_module_url: 'https://aliumgamingbak.myfileshosting.com:6007',
        base_url: 'https://aliumgamingbak.myfileshosting.com/api',
        admin_backend_url: 'https://aliumgamingbak.myfileshosting.com',
        paypalSandBoxUrl: 'https://api-m.sandbox.paypal.com',
        paypalLiveUrl: 'https://api-m.paypal.com',
        react_app_endpoint: 'http://localhost:3000',
        paypalClientId: 'AWrtumbmv_RmehdaWXBN54RDppOvkMP_98VVmhpdqwQ0b9TjGnvhqR1bRR14CZK0TrTlp9lvZMC13cRN',
        paypalSecret: 'ENQij_QDUmt4ht9aIwfYiB1e-CjM8cwKliCTOSCXfS-ZftqXB3SHd-sGa3t4peo1cfKOskk8Lr5HgKaG',
        recordsPerPage: 12,
        recordsPerPageTable: 12,
        stripeKey: 'pk_test_51NUAgRG5smbJHPMoio9Xwvor0AnagTXRXUVpJ676ru9SqEPzwsy29cmnIY7sswcI39BJqfyWB6RAUdqTokFIL3XR00ERg9hNh5',
        stripeSecret: 'sk_test_51NUAgRG5smbJHPMoKYPJtCkkVtGkx54yISlVCZ1GYSRzFMVULyet4Q1bxFA8GyNoc7VGIqfATcRgekuFXEl2BG8K00N4tkyBWW'
    }
} else{
    appSettings = {
        chat_module_url:  'https://admin.alium-gaming.com:6007',
        base_url:  'https://admin.alium-gaming.com/api',
        admin_backend_url:  'https://admin.alium-gaming.com',
        paypalSandBoxUrl : 'https://api-m.sandbox.paypal.com',
        paypalLiveUrl : 'https://api-m.paypal.com',
        react_app_endpoint : 'http://localhost:3000',
        paypalClientId : 'AWrtumbmv_RmehdaWXBN54RDppOvkMP_98VVmhpdqwQ0b9TjGnvhqR1bRR14CZK0TrTlp9lvZMC13cRN',
        paypalSecret : 'ENQij_QDUmt4ht9aIwfYiB1e-CjM8cwKliCTOSCXfS-ZftqXB3SHd-sGa3t4peo1cfKOskk8Lr5HgKaG',
        recordsPerPage: 12,
        recordsPerPageTable: 12,
        // stripeKey : 'pk_test_51NUAgRG5smbJHPMoio9Xwvor0AnagTXRXUVpJ676ru9SqEPzwsy29cmnIY7sswcI39BJqfyWB6RAUdqTokFIL3XR00ERg9hNh5',
        // stripeSecret :'sk_test_51NUAgRG5smbJHPMoKYPJtCkkVtGkx54yISlVCZ1GYSRzFMVULyet4Q1bxFA8GyNoc7VGIqfATcRgekuFXEl2BG8K00N4tkyBWW'
        stripeKey : 'pk_live_51NUAgRG5smbJHPMoinMdJFVHbdPLgKozQPaaArazB28c0zTSb99hs4xRM3L19FZY7HmWHLM0rCZelLPgnpcClnOY00edn2ic2v',
        stripeSecret :'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'
    }
}

export default appSettings