import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const ContentLoading = () => {
	const { loading } = useSelector((state) => state.common)
	const location = useLocation();
	return (
		<div className="myspinner page-loader-container" style={{height: '100%', position: location.pathname.includes("product-search-list") ? 'fixed' : 'absolute', display : loading ? 'flex' : 'none'}}>
            <div id='new-preloader'>
            <div className='triangle'></div>
            <div className='triangle'></div>
            <div className='triangle'></div>
            <div className='triangle'></div>
            <div className='triangle'></div>
            </div>
        </div>
	)
}